import { useMemo } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import firebase from "@helpers/firebase";

const auth = getAuth(firebase.apps[0]);

export default function useAuth() {
  const [user, loading] = useAuthState(auth);
  return useMemo(() => ({ user, loading }), [user, loading]);
}
