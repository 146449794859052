import { createContext, useContext } from "react";

const context = createContext({});

export default function PageProvider(props) {
  return <context.Provider {...props} />;
}

export const usePage = () => useContext(context);

export const usePageValue = (key) => useContext(context)[key];
