export const theme = {
  name: "coinscope",
  rounding: 4,
  spacing: 24,
  defaultMode: "dark",
  global: {
    edgeSize: {
      none: "0px",
      hair: "1px",
      xxsmall: "4px",
      xsmall: "8px",
      small: "16px",
      medium: "24px",
      large: "32px",
      xlarge: "48px",
      responsiveBreakpoint: "small",
    },

    breakpoints: {
      medium: { value: 1280 },
    },
    hover: {
      background: {
        color: "dark-1",
      },
    },
    colors: {
      focus: false,
      brand: "#40BDCA",
      "accent-1": "#40BDCA",
      "background-back": {
        dark: "#555",
        light: "#EEEEEE",
      },
      "background-front": {
        dark: "#111",
        light: "#FFFFFF",
      },
      "background-contrast": {
        dark: "#FFFFFF22",
        light: "#11111111",
      },
      border: {
        dark: "#313D5B",
        light: "#313D5B",
      },
      placeholder: "#666",
      containerBackground: "#1d2639",
    },
    font: {
      family: `Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
      size: "16px",
    },
    focus: {
      border: { color: "none" },
    },
    input: {
      font: "normal",
    },
    drop: {
      zIndex: 1300,
    },
  },

  formField: {
    disabled: {
      background: {
        color: { dark: "#293450" },
      },
    },
  },

  text: {
    xsmall: {
      size: "12px",
      height: "18px",
    },
    small: {
      size: "14px",
      height: "20px",
    },
    medium: {
      size: "16px",
      height: "24px",
    },
    large: {
      size: "18px",
      height: "28px",
    },
    xlarge: {
      size: "24px",
      height: "28px",
    },
    xxlarge: {
      size: "36px",
      height: "44px",
    },
  },

  heading: {
    level: {
      3: {
        small: {
          size: "18px",
        },
      },
    },
  },
  anchor: {
    color: "light-1",
    fontWeight: 600,
    hover: {
      textDecoration: "none",
      extend: `color: #999;`,
      // extend: {
      //   color: "dark-4",
      //   extend: `color: red;`,
      // },
      // color: "dark-4",
    },
  },
  table: {
    header: {
      pad: { vertical: "medium", horizontal: "xsmall" },
      background: "#26272C",
    },
    body: {
      background: "#26272C",
      pad: { vertical: "small", horizontal: "xsmall" },
    },
  },
  paragraph: {
    small: {
      maxWidth: "624px",
    },
    medium: {
      maxWidth: "624px",
    },
    large: {
      maxWidth: "624px",
    },
    xlarge: {
      maxWidth: "624px",
    },
    xxlarge: {
      maxWidth: "624px",
    },
  },
  // select: {
  //   options: {
  //     container: {
  //       background: "#1b2130",
  //     },
  //     exend:{
  //       hover:{
  //         background:{
  //           color:"blue:P"
  //         }
  //       }
  //     }
  //   },
  // },
  // tip: {
  //   drop: {
  //     background: { color: "brand", opacity: 0.9 },
  //     margin: { vertical: "small" },
  //     round: "medium",
  //     elevation: "large",
  //   },
  //   content: {
  //     elevation: "none",
  //     background: "none",
  //     pad: "xsmall",
  //   },
  // },
};
