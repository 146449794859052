import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import "firebase/compat/auth";

const FIREBASE_CONFIG = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_APIKEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTHDOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECTID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_SENDERID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APPID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENTID,
};

if (!firebase.apps.length) {
  firebase.initializeApp(FIREBASE_CONFIG);
}

export const sendEmailVerification = () => {
  const url = `${location.origin}/auth/verify?verifiedEmail=${
    firebase.auth().currentUser.email
  }`;

  return firebase.auth().currentUser.sendEmailVerification({
    url,
  });
};

export const sendPasswordResetEmailFromClient = (email) => {
  const url = `${location.origin}/login`;

  return firebase.auth().sendPasswordResetEmail(email, {
    url,
  });
};

export default firebase;
