import { useEffect } from "react";
import firebase from "@helpers/firebase";
import setCookie from "@helpers/setCookie";
import useAuth from "./useAuth";

export default function Auth() {
  const { user } = useAuth();

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) user.getIdTokenResult(true);
    });

    firebase.auth().onIdTokenChanged((nextUser) => {
      setCookie("coinscope_user_id", nextUser ? nextUser.uid : "");
    });
  }, []);

  useEffect(() => {
    if (!user) return;

    const storage = (event) => {
      if (event.key === "coinscopeVerified")
        firebase.auth().currentUser.getIdTokenResult(true);
    };

    window.addEventListener("storage", storage);
    return () => window.removeEventListener("storage", storage);
  }, [user]);

  return null;
}
