import Axios from "axios";
import React, { useState, useEffect, useMemo } from "react";
import isbot from "isbot";
import { Grommet } from "grommet";
import dynamic from "next/dynamic";
import { Box } from "grommet";
import styled from "styled-components";
import Router from "next/router";
import { useDetectAdBlock } from "adblock-detect-react";
import { isDev } from "@helpers/environment";
import useTrackEvents from "@cybertools/ui/es/hooks/useTrackEvents";
import NotificationsProvider from "@cybertools/ui/es/organisms/notifications";
import GlobalStyles, { theme } from "@components/globalStyles";
import getCss from "@components/loading/css";
import Auth from "@organisms/auth";
import PageProvider from "@organisms/page/provider";
import { unsetToken, walletConnect } from "@organisms/web3/errorMessages";

const Container = styled(Box)`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 4px;
  z-index: 100;
  ${getCss({ duration: "1.2s" })}
`;

const shouldLoadSentry =
  !isDev() &&
  process.env.NEXT_PUBLIC_SENTRY_DSN &&
  process.env.NEXT_PUBLIC_SENTRY_AUTH_TOKEN;

const SentryErrorBoundary = shouldLoadSentry
  ? dynamic(
      () => import("@sentry/react").then(({ ErrorBoundary }) => ErrorBoundary),
      { ssr: true }
    )
  : (props) => props.children;

const Error500 = shouldLoadSentry
  ? dynamic(() => import("@organisms/error500"), { ssr: true })
  : () => null;

const nonErrorExceptionPrefix = "Non-Error exception captured with keys";

if (shouldLoadSentry) {
  import("@sentry/react").then(({ init, BrowserTracing }) => {
    init({
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
      integrations: [new BrowserTracing()],
      ignoreErrors: [`Error: ${walletConnect}`, `Error: ${unsetToken}`],
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
      beforeSend: (event, hint) => {
        const hasUnHandledErrors = event.exception?.values?.some(
          (error) => !error.mechanism?.handled
        );

        if (hasUnHandledErrors) return null;

        const errorIndex = event.exception?.values?.findIndex((error) =>
          error.value.startsWith(nonErrorExceptionPrefix)
        );
        const isNotErrorInstance =
          !(hint.originalException instanceof Error) || errorIndex !== -1;
        if (isNotErrorInstance && hint.originalException) {
          event.exception.values[errorIndex].type = "Error";
          event.exception.values[errorIndex].value =
            hint.originalException.data?.message ||
            hint.originalException.message;
        }

        return event;
      },
    });
  });
}

function App({ Component, pageProps }) {
  const { requestAuth, ua, coinscopeCoin, notifications } = pageProps;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const adBlockDetected = !isDev() && useDetectAdBlock();

  const deviceProps = useMemo(
    () => ({
      bot: isbot(ua),
      iphone: /iphone/i.test(ua),
      pwa:
        typeof window !== "undefined" &&
        window.matchMedia("(display-mode: standalone)").matches,
    }),
    [ua]
  );

  useTrackEvents();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    Axios.interceptors.request.use(
      (config) => {
        if (config.method === "get") setLoading(true);
        return config;
      },
      (error) => Promise.reject(error)
    );

    Axios.interceptors.response.use(
      (response) => {
        setLoading(false);
        return response;
      },
      (error) => {
        setLoading(false);
        return Promise.reject(error);
      }
    );
  }, []);

  useEffect(() => {
    Router.events.on("routeChangeStart", () => setLoading(true));
    Router.events.on("routeChangeComplete", () => setLoading(false));

    return () => {
      Router.events.off("routeChangeStart", () => setLoading(true));
      Router.events.off("routeChangeComplete", () => setLoading(false));
    };
  }, []);

  return (
    <>
      <GlobalStyles />
      <Grommet theme={theme} themeMode="dark" userAgent={ua}>
        <PageProvider
          value={{
            requestAuth,
            ua,
            coinscopeCoin,
            adBlockDetected,
            notifications,
            ...deviceProps,
          }}
        >
          <NotificationsProvider>
            <Auth />
            {loading && <Container />}
            <SentryErrorBoundary fallback={<Error500 />}>
              <Component {...pageProps} />
            </SentryErrorBoundary>
          </NotificationsProvider>
        </PageProvider>
      </Grommet>
    </>
  );
}

export default App;
