import { css, keyframes } from "styled-components";

const animation = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

export default function getCss({ duration, once }) {
  return css`
    animation-duration: ${duration};
    animation-fill-mode: forwards;
    animation-iteration-count: ${once ? "1" : "infinite"};
    animation-name: ${animation};
    animation-timing-function: linear;
    background: linear-gradient(
      to right,
      #1b2130 10%,
      #313d5b 18%,
      #1b2130 33%
    );
    background-size: 800px 104px;
  `;
}
