import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    background: #0e1019
  }

  a {
    color: #1fc7d4;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    margin: 5px;
    background: #00000000;
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }

  :hover::-webkit-scrollbar-thumb {
    background: #7f7f86;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #7f7f86;
  }

`;

export { theme } from "./theme";
export default GlobalStyles;
